// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
// require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

import "core-js/stable"
import "regenerator-runtime/runtime"

require('bootstrap')
require('admin-lte')
require('select2/dist/js/select2')

const moment = require('moment/moment.js')
require('daterangepicker/daterangepicker.js')

const bsCustomFileInput = require('bs-custom-file-input/dist/bs-custom-file-input')

import "@fortawesome/fontawesome-free/js/all"

require("../stylesheets/application")
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
const axios = require('axios')

window.addEventListener('load', (event) => {
  moment.locale('ja')
  $(".datepicker").daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    drops: 'auto',
    autoApply: true, // これが効かない
    locale: {
      daysOfWeek: [
        "日", "月", "火", "水", "木", "金", "土"
      ],
      monthNames: [
        "1月", "2月", "3月",
        "4月", "5月", "6月",
        "7月", "8月", "9月",
        "10月", "11月", "12月"
      ],
      format: 'YYYY/MM/DD',
      applyLabel: '選択',
      cancelLabel: 'キャンセル'
    }
  }).on('apply.daterangepicker', (ev, picker) => {
    picker.element.val(picker.startDate.format("YYYY/MM/DD"))
  })

  // timePicker あると autoReply 無効になる
  const dateTimeFields = document.querySelectorAll(".datetimepicker")
  dateTimeFields.forEach((dateTimeField) => {
    const optionDrops = dateTimeField.dataset.optionDrops || 'auto'
    const optionTimePickerIncrement = Number.parseInt(dateTimeField.dataset.optionTimePickerIncrement, 10) || 1

    const options = {
      singleDatePicker: true,
      autoUpdateInput: false,
      timePicker: true,
      timePicker24Hour: true,
      timePickerIncrement: optionTimePickerIncrement,
      drops: optionDrops,
      locale: {
        daysOfWeek: [
          "日", "月", "火", "水", "木", "金", "土"
        ],
        monthNames: [
          "1月", "2月", "3月",
          "4月", "5月", "6月",
          "7月", "8月", "9月",
          "10月", "11月", "12月"
        ],
        format: 'YYYY/MM/DD hh:mm',
        applyLabel: '選択',
        cancelLabel: 'キャンセル'
      }
    }

    $(dateTimeField).daterangepicker(options).on('apply.daterangepicker', (ev, picker) => {
      picker.element.val(picker.startDate.format("YYYY/MM/DD HH:mm"))
    })
  })

/*
  $('[data-toggle="popover"]').on("mouseenter", (event) => {
    const i = event.target
    $(i).popover("show")
    $(".popover").on("mouseleave", (event) => {
      $(i).popover('hide')
    })
  }).on("mouseleave", (event) => {
    const i = event.target
    setTimeout(() => {
      if (!$(".popover:hover").length) {
        $(i).popover("hide")
      }
    }, 300)
  })
*/

  // File Input
  bsCustomFileInput.init()

  axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
  axios.defaults.headers['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

/*
  $(".agreements").on('click', (event) => {
    const agreements = $('.agreements')
    const checkedAgreements = $('.agreements:checked')
    $(".button-bank-account-submit").prop({disabled: (agreements.length != checkedAgreements.length)})
  });

  $("#recipient_bank_code").on('blur', (ev) => {
    const bankCode = document.getElementById("recipient_bank_code")
    if (bankCode.value.length == 4) {
      axios.get(`/api/banks/${bankCode.value}`).then((r) => {
        if ($("#recipient-bank-name")[0]) {
          $("#recipient-bank-name").text(r.data.name)
        } else if ($("#recipient_bank_name")[0]) {
          $("#recipient_bank_name").val(r.data.name)
        }
      });
    }
  });

  $("#recipient_branch_code").on('blur', (ev) => {
    const bankCode = document.getElementById("recipient_bank_code")
    const branchCode = document.getElementById("recipient_branch_code")
    if (bankCode.value.length == 4 && branchCode.value.length == 3) {
      axios.get(`/api/banks/${bankCode.value}/branches/${branchCode.value}`).then((r) => {
        console.debug(r.data)
        if ($("#recipient-branch-name")[0]) {
          $("#recipient-branch-name").text(r.data.name)
        } else if ($("#recipient_branch_name")[0]) {
          $("#recipient_branch_name").val(r.data.name)
        }
      });
    }
  });
*/

  // 管理画面用
/*
  const clippables = document.querySelectorAll(".clippable")
  clippables.forEach((clippable) => {
    if (navigator.clipboard) {
      clippable.addEventListener('click', (event) => {
        const text = event.currentTarget.dataset.copyText
        navigator.clipboard.writeText(text)
        $(document).Toasts('create', {
          title: 'コピーしました',
          body: text,
          autohide: true,
          delay: 5000,
          class: 'bg-info'
        })
        event.preventDefault()
      })
    } else {
      clippable.addEventListener('click', (event) => {
        $(document).Toasts('create', {
          title: 'コピーできませんでした',
          body: 'ご利用中のブラウザはコピー機能に対応しておりません。',
          autohide: true,
          delay: 5000,
          class: 'bg-warning'
        })
        event.preventDefault()
      })
    }
  })
*/

  const testFlights = document.querySelectorAll(".test-flight")
  testFlights.forEach((testFlight) => {
    testFlight.addEventListener('click', (event) => {
      if (confirm("テストメールを送信してもよろしいですか?")) {
        const modal = event.currentTarget.closest('.modal')
  
        const subject = modal.querySelector(`[name$="[subject]"],[name$="[completion_subject]"]`).value
        const senderName = modal.querySelector(`[name$="[sender_name]"],[name$="[completion_sender_name]"]`).value
        const senderFrom = modal.querySelector(`[name$="[sender_from]"],[name$="[completion_sender_from]"]`).value
        const body = modal.querySelector(`[name$="[body]"],[name$="[completion_body]"]`).value
        axios.post(event.currentTarget.dataset.testFlightUrl, {
          subject: subject,
          sender_name: senderName,
          sender_from: senderFrom,
          body: body
        }).then((response) => {
          $(document).Toasts('create', {
            title: 'テストメール',
            body: `担当者情報に登録されているメールアドレス(${response.data.email})宛に配信しました。`,
            autohide: true,
            delay: 5000,
            class: 'bg-success'
          })
        }).catch((error) => {
          console.log(error)
        })
      }

      event.preventDefault();
    })
  })

  const toasters = document.querySelectorAll('.toaster')
  toasters.forEach((toaster) => {
    toaster.addEventListener('click', (event) => {
      $(document).Toasts('create', {
        title: event.currentTarget.dataset.toastTitle,
        body: event.currentTarget.dataset.toastBody,
        autohide: true,
        delay: 5000,
        class: event.currentTarget.dataset.toastBackground,
      })

      event.preventDefault()
    })
  })
  

  const buttons = document.querySelectorAll('.insertion-button')
  buttons.forEach((button) => {
    button.addEventListener('click', (event) => {
      const target = document.querySelector(`.modal.show #${event.currentTarget.dataset.targetId}`)
      const insertionValue = event.currentTarget.dataset.insertionValue
      const before = target.value.substr(0, target.selectionStart)
      const after = target.value.substr(target.selectionEnd, target.value.length)
      target.value = `${before}${insertionValue}${after}`

      event.preventDefault()
    })
  })

  const tags = document.querySelectorAll('.replace-tag')
  tags.forEach((tag) => {
    tag.addEventListener('click', (event) => {
      const target = document.querySelector(`.modal.show #${event.currentTarget.dataset.targetId},.form-message-template #${event.currentTarget.dataset.targetId}`)
      const bindingTag = event.currentTarget.dataset.bindingTag
      const before = target.value.substr(0, target.selectionStart)
      const after = target.value.substr(target.selectionEnd, target.value.length)
      target.value = `${before}${bindingTag}${after}`

      event.preventDefault()
    })
  })

  const messageTemplates = document.querySelectorAll(".load-message-template")
  messageTemplates.forEach((messageTemplate) => {
    messageTemplate.addEventListener('click', (event) => {
      const modal = event.currentTarget.closest('.modal')
      const messageTemplateUrl = event.currentTarget.href
      const distributionId = event.currentTarget.dataset.distributionId
      axios.get(messageTemplateUrl, {params: {distribution_id: distributionId}}).then((response) => {
        const subject = modal.querySelector('input[name$="[subject]"],input[name$="[completion_subject]"]')
        subject.value = response.data.subject
        const senderName = modal.querySelector('input[name$="[sender_name]"],input[name$="[completion_sender_name]"]')
        senderName.value = response.data.sender_name
        const senderFrom = modal.querySelector('select[name$="[sender_from]"],select[name$="[completion_sender_from]"]')
        senderFrom.value = response.data.sender_from
        const body = modal.querySelector('textarea[name$="[body]"],textarea[name$="[completion_body]"]')
        body.value = response.data.body
      })

      event.preventDefault()
    })
  })

  const editMessages = document.querySelectorAll('edit-message')
  editMessages.forEach((editMessage) => {
    editMessage.addEventListener('click', (event) => {
      const url = event.currentTarget.href
      axios.get(url, {}).then((response) => {
        console.debug(response.data.message)
      })

      event.preventDefault()
    })
  })

  $('.btn-clear-field').on('click', (ev) => {
    $('input[type="text"],select', $(ev.target).closest('form')).val('');
    $('input[type="checkbox"]', $(ev.target).closest('form')).prop('checked', false);
    $('.select2-hidden-accessible').val(null).trigger('change');
  });

  // POPUPいっこで大丈夫版
  // こっちに変えていきたい:w
  const messageLinks = document.querySelectorAll(".edit-recipient-message")
  messageLinks.forEach((messageLink) => {
    messageLink.addEventListener('click', (event) => {
      const url = event.target.href
      const recipientCount = event.target.dataset.recipientCount

      const form = document.querySelector(".modal-edit-message form")
      form.setAttribute('action', url)
      const label = document.querySelector(".modal-edit-message .recipient-count")
      if (label) {
        label.innerText = recipientCount
      }

      // 未承認は送信できない
      // このメソッドを使わない呼び出しはテンプレートに値を渡している
      form.dataset.approved = event.target.dataset.approved;

      // 初期化的な
      // これで網羅できる
      const fields = form.querySelectorAll('input[type="text"],textarea,selectbox')
      fields.forEach((field) => {
        field.value = ''
      })

      const tmpls = form.querySelectorAll('a.load-message-template')
      const distributionId = event.target.dataset.distributionId
      tmpls.forEach((tmpl) => {
        tmpl.dataset.distributionId = distributionId
      })

      // 置換ボタンのデータ読み込み
      const distributionUrl = event.target.dataset.distributionUrl
      if (distributionUrl) {
        axios.get(distributionUrl, {}).then((response) => {
          const insertionButtons = document.querySelectorAll('.insertion-button')
          insertionButtons.forEach((insertionButton) => {
            const attr = insertionButton.dataset.attributeName
            const value = response.data[attr]
            insertionButton.dataset.insertionValue = value
          })
        })
      }

      // 初期データがある場合、edit
      const editUrl = event.target.dataset.editUrl
      if (editUrl) {
        axios.get(editUrl, {}).then((response) => {
          const form = document.querySelector(".modal-edit-message form")

          const subject = response.data.completion_subject
          const senderName = response.data.completion_sender_name
          const senderFrom = response.data.completion_sender_from
          const body = response.data.completion_body
  
          form.querySelector('[name="message[subject]"],[name="distribution[completion_subject]"]').value = subject
          form.querySelector('[name="message[sender_name]"],[name="distribution[completion_sender_name]"]').value = senderName
          form.querySelector('[name="message[sender_from]"],[name="distribution[completion_sender_from]"]').value = senderFrom
          form.querySelector('[name="message[body]"],[name="distribution[completion_body]"]').value = body
        })
      }

      $('.modal-edit-message').modal('show')

      event.preventDefault()
    })
  })

  $('.form-message-editor').on('ajax:success', (event) => {
    console.log(event)
    const messageId = event.detail[0].id
    const reservedAtCell = document.querySelector(`tr.row-message-${messageId} td.column-message-reserved_at`)
    if (reservedAtCell) {
      if (event.detail[0].reserved_at) {
        const reservedAt = moment(event.detail[0].reserved_at)
        reservedAtCell.innerText = reservedAt.format("YYYY/MM/DD HH:mm")
      } else {
        reservedAtCell.innerText = ""
      }
    }
    const subjectCell = document.querySelector(`tr.row-message-${messageId} td.column-message-subject`)
    if (subjectCell) {
      const subject = event.detail[0].subject
      subjectCell.innerText = subject
    }

    $(document).Toasts('create', {
      title: '通知設定',
      body: '通知の保存が完了しました。',
      autohide: true,
      delay: 5000,
      class: 'bg-success'
    })

    $('.modal').modal('hide')
  })

  
  // バリデーション反映
  $('.form-message-editor').on('ajax:error', (event) => {
    const messages = JSON.parse(event.detail[2].response)
    const elms = event.target.querySelectorAll('[name$="[subject]"],[name$="[sender_name]"],[name$="[body]"]')
    elms.forEach((e) => {
      e.className = e.className.replace('is-invalid', '')
    })
    for (const key in messages.errors) {
      const slctr = `[name$="[${key}]"]`
      const elm = event.target.querySelector(slctr)
      elm.className = `${elm.className} is-invalid`
    }
    
  })

  $('.tab-message-editor-preview').on('show.bs.tab', (event) => {
    console.log(event)
    const modal = event.currentTarget.closest('.modal')
    const url = modal.querySelector(`.form-message-editor`).dataset.previewUrl
    const subject = modal.querySelector(`input[name$="[subject]"],input[name$="[completion_subject]"]`)
    const senderName = modal.querySelector(`input[name$="[sender_name]"],input[name$="[completion_sender_name]"]`)
    const body = modal.querySelector(`textarea[name$="[body]"],textarea[name$="[completion_body]"]`)
    axios.post(url, {
      message: {
        subject: subject.value,
        sender_name: senderName.value,
        body: body.value
      }
    }).then((response) => {
      console.debug(response)
      const modal = document.querySelector(".modal.show")
      const previewSubject = modal.querySelector(`.preview-subject`)
      previewSubject.innerText = response.data.preview_subject
      const previewSenderName = modal.querySelector(`.preview-sender-name`)
      previewSenderName.innerText = response.data.preview_sender_name
      const previewBody = modal.querySelector(`.preview-body`)
      previewBody.innerText = response.data.preview_body
    })
  })

  $(".modal-edit-message").on('show.bs.modal', (event) => {
    // 置換ボタンのデータ読み込み
    const form = event.currentTarget.querySelector("form")
    const distributionUrl = form.dataset.distributionUrl
    if (distributionUrl) {
      axios.get(distributionUrl, {}).then((response) => {
        const insertionButtons = document.querySelectorAll('.insertion-button')
        insertionButtons.forEach((insertionButton) => {
          const attr = insertionButton.dataset.attributeName
          const value = response.data[attr]
          insertionButton.dataset.insertionValue = value
        })
      })
    }

    // show editor tab
    $('#message-editor-tab li:first-child a').tab('show')

    // 未承認は送信出来ない
    if (form.dataset.approved == 'false' && event.currentTarget.querySelector('#message_processing_state_reserved')) {
      event.currentTarget.querySelector('#message_processing_state_reserved').disabled = true;
      event.currentTarget.querySelector('#message_reserved_at').disabled = true;
      event.currentTarget.querySelector('#message_processing_state_stamped').disabled = true;
    }
  })

  $('.memo-popover').popover({
    trigger: 'hover',
    container: 'body'
  })

  $('#recipient_form_processing_state').select2({
    width: 'auto',
    placeholder: '(状態) ※複数選択可能'
  });
});
